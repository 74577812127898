import React, { useState, useEffect } from "react";
import "./parkingCal.css";
import logo from "../../assets/imgs/logo.png";
import map from "../../assets/imgs/map2.png";
import p1 from "../../assets/imgs/p1.svg";
import p2 from "../../assets/imgs/p2.svg";
import p3 from "../../assets/imgs/p3.svg";
import p4 from "../../assets/imgs/p4.svg";
import p5 from "../../assets/imgs/p5.svg";
import p6 from "../../assets/imgs/p6.svg";
import p7 from "../../assets/imgs/p7.svg";

import pin from "../../assets/imgs/icon-pin.svg";

import parkingsService from "../../services/parkingsService";
import $ from "jquery";
import { parkingLocation } from "ultils/const";

const ParkingMap = (props: any) => {
	const { setLoading } = props;
	const [parkingsList, setParkingsList] = useState<any>(null);
	const [time, setTime] = useState<any>(null);

	const updateTime = () => {
		const now = new Date();
		const hours = now.getHours().toString().padStart(2, "0");
		const minutes = now.getMinutes().toString().padStart(2, "0");
		setTime(`${hours}時${minutes}分時点`);
	};

	const getListParkings = async (page = 1, data = {}) => {
		try {
			setLoading(true);
			const response = await parkingsService.getListParkings({ page, ...data });
			if (response !== null && response !== undefined) {
				setParkingsList(response.items);
				updateTime();
			}
		} catch (err) {
			console.log("err", err);
		} finally {
			setLoading(false);
		}
	};

	const parkingCalculator = (
		percent: number,
		isCss = false,
		isClose = false,
		isAdjust = false,
	) => {
		const percentCal = Number(percent.toFixed(0));

		if (isClose) return isCss ? "type-5" : "閉鎖中";
		if (isAdjust) return isCss ? "type-6" : "調整中";
		if (percentCal < 0) return isCss ? "type-1" : "空あり";
		if (0 <= percentCal && percentCal <= 60) return isCss ? "type-1" : "空あり";
		else if (61 <= percentCal && percentCal <= 70)
			return isCss ? "type-2" : "やや混雑";
		else if (71 <= percentCal && percentCal <= 95)
			return isCss ? "type-3" : "混雑";
		else if (96 <= percentCal && percentCal <= 100)
			return isCss ? "type-4" : "満";
		else return isCss ? "type-4" : "満";
	};

	useEffect(() => {
		getListParkings();
		const interval = setInterval(() => {
			getListParkings();
		}, 180000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		setTimeout(() => {
			$('a[href^="#"]').click(function () {
				let href: any = $(this).attr("href");
				let target: any = $(href == "#" || href == "" ? "html" : href);
				let index = $(this).index() - 1;

				let w = $(window).width();
				let space = 80;
				if (w && w <= 768) {
					space = 30;
				}
				let position = target?.offset().top - space;

				$(".table-progress-item-map")
					.removeClass("current")
					.eq(index)
					.addClass("current");
				$("html, body").animate({ scrollTop: position }, 800, "swing");

				return false;
			});
		}, 500);
	}, []);

	return (
		<div className='dashboard-map'>
			<h1 className='logo'>
				<img src={logo} alt='' />
			</h1>
			<p className='caption'>
				<em>MAPのPマークをクリックすると現在の混雑状況に移動します</em>
			</p>
			<div className='mapping-area'>
				<div className='mapping-inner'>
					<img src={map} alt='' />
					<a href='#info-area' className='mapping-pin p1'>
						<img src={p1} alt='' />
					</a>
					<a href='#info-area' className='mapping-pin p2'>
						<img src={p2} alt='' />
					</a>
					<a href='#info-area' className='mapping-pin p3'>
						<img src={p3} alt='' />
					</a>
					<a href='#info-area' className='mapping-pin p4'>
						<img src={p4} alt='' />
					</a>
					<a href='#info-area' className='mapping-pin p5'>
						<img src={p5} alt='' />
					</a>
					<a href='#info-area' className='mapping-pin p6'>
						<img src={p6} alt='' />
					</a>
					<a href='#info-area' className='mapping-pin p7'>
						<img src={p7} alt='' />
					</a>
				</div>
			</div>
			<p id='info-area' className='info'>
				駐車場混雑状況
			</p>
			<p className='time'>{time}</p>
			<div className='dashboard-container-map d-flex flex-column'>
				<div className='table-progress-map'>
					<div className='table-progress-row-map d-flex'>
						{parkingsList?.length > 0 &&
							parkingsList?.map((parking: any, id: number) => (
								<div className='table-progress-item-map d-flex flex-row'>
									<div className='table-progress-title-map d-flex flex-row j-center al-center'>
										<label>{parking?.name}</label>
									</div>
									<div
										className={`table-progress-content-map d-flex flex-column j-center al-center ${parkingCalculator(
											(parking.now_num_cars * 100) / parking.max_num_cars,
											true,
											parking?.is_closed,
											parking?.is_adjusted,
										)}`}
									>
										<div className='d-flex flex-row w-full j-center al-center'>
											<div style={{ fontWeight: 600 }}>
												{parkingCalculator(
													(parking.now_num_cars * 100) / parking.max_num_cars,
													false,
													parking?.is_closed,
													parking?.is_adjusted,
												)}
											</div>
										</div>
									</div>
									<a
										href={parkingLocation[id]}
										target='_blank'
										className='table-progress-icon'
									>
										<img src={pin} alt='' />
									</a>
								</div>
							))}
					</div>
				</div>
				<div className='note-text d-flex flex-column'>
					<span>
						＊空き情報の更新タイミングには時間差がございますので、目安としてご利用ください。
					</span>
					<span>
						＊混雑時に入庫待ちの車列が道路に達している場合は、ご利用いただけない場合がございます。
					</span>
					<span>
						＊満車の場合はご利用いただけませんので、予めご了承ください。
					</span>
					<span>＊ご利用の際はお時間に余裕を持ってお越しください。</span>
				</div>
			</div>
			<p className='copy'>
				<small>
					Copyright &copy; kariya-city general athletic park All Rights
					Reserved.
				</small>
			</p>
		</div>
	);
};
export default ParkingMap;
